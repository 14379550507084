import React from "react"
import axios from 'axios';
import Uploader from "./Upload/AdsUpload"
// import Editor from "./Editor/Wysiwyg"
import { MainContainer, View, Row } from '../containers/MainWrapper'
import Head from "./Dashboard/Head"
import { DashboardViewport } from "../containers/Dashboard"

class AdForm extends React.Component {

  state = {
    loaded: false,
    data: {
      product_name: null,
      category: null,
      catlink: null,
      sub_category: null,
      subcatlink: null,
      pictures: null,
      pictures_data: null,
      cityname: null,
      statename: null,
      price: null,
      negotiable: null,
      created_at: null,
      contact_phone: null,
      phone: null,
      view: null,
      description: null,
      tags: null,
      user: { 
        name: null
      }
    },
    place: null,
    category: null,
    sub_category: null,
    id_place: null,
    id_category: null,
    id_sub_category: null,
    categoriesTree: null,
    mail_ubject: null,
  }

  componentDidMount() {
    const { id } = this.props;
    const _self = this;
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_ads',
      id: parseInt(id),
      options: 'categories'
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (Object.keys(result.data.collection).length === 1) {
        const { categories, sub_categories } = result.data;
        const categoriesTree = categories.map((item) => {
          const subcategories = sub_categories.filter((i) => parseInt(i.main_cat_id) === parseInt(item.cat_id));
          return {
            ...item,
            subcategories: subcategories
          }
        }).filter(item => item.subcategories.length > 0);

        _self.setState({ 
          data: result.data.collection[id],
          place: result.data.place,
          category: result.data.category,
          sub_category: result.data.sub_category,
          id_place: result.data.id_place,
          id_category: result.data.id_category,
          id_sub_category: result.data.id_sub_category,
          categoriesTree: categoriesTree,
          mail_subject: `AFC Bayonne Anglet Biarritz - Annonce : ${result.data.collection[id].product_name}`,
          loaded: true
        })
      }
    });
  }

  handleNegotiable = (e) => {
    // console.log(e)
  }

  handleHidePhone = (e) => {
    // console.log(e)
  }

  render() {
    const { 
      // id_category, 
      id_sub_category,
      categoriesTree,
      // mail_subject,
      data: {
        product_name,
        // category,
        // catlink,
        // sub_category,
        // subcatlink,
        pictures,
        pictures_data,
        // cityname,
        // statename,
        price,
        negotiable,
        // created_at,
        contact_phone,
        phone,
        // view,
        description,
        tags,
        // user
      }
    } = this.state;
    
    return (
      <MainContainer 
        style={{ background: '#f8f8f8' }} 
        className={this.state.loaded ? 'loaded' : ''}
      >
        {
          this.state.loaded && (
            <React.Fragment>
              <React.Fragment>
                <Head page={this.props.page} />
                <View>
                  <Row>
                    <div className="col-lg-9">
                      <DashboardViewport>
                        <form method="post">
                          <div className="headline">Détails de l'annonce</div>
                          <Row>
                            <div className="col-md-12">
                              <div className="content">

                                <div className="fields">
                                  <h5>Catégorie</h5>
                                  <div className="inputs">
                                    <select name="category" id="category" defaultValue={parseInt(id_sub_category)}>
                                      {
                                        categoriesTree.map((item, key) => {
                                          return (
                                            <optgroup label={item.cat_name} key={`main-category-${key}`}>
                                              {
                                                item.subcategories.map((i, index) => {
                                                  return (
                                                    <option value={i.sub_cat_id} key={`sub-category-${index}`}>
                                                      {i.sub_cat_name}
                                                    </option>
                                                  )
                                                })
                                              }
                                            </optgroup>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Titre</h5>
                                  <div className="inputs">
                                    <input 
                                      type="text" 
                                      name="name" 
                                      id="name" 
                                      defaultValue={product_name}
                                    />
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Description</h5>
                                  <div className="inputs">
                                    {/* <Editor defaultValue={description} /> */}
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Images</h5>
                                  <div className="inputs">
                                    <Uploader 
                                      images={pictures}
                                      pictures_data={pictures_data}
                                    />
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Prix</h5>
                                  <div className="inputs">
                                    <Row>
                                      <div className="col-md-6">
                                        <input 
                                          type="text" 
                                          name="price" 
                                          id="price" 
                                          defaultValue={price}
                                          placeholder="Saisissez votre prix"
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="checkbox">
                                          <input 
                                            type="checkbox" 
                                            name="negociate" 
                                            id="negociate"
                                            onChange={this.handleNegotiable}
                                            checked={negotiable}
                                          />
                                          <label htmlFor="negociate">
                                            Négociable
                                          </label>
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Numéro de téléphone</h5>
                                  <div className="inputs">
                                    <Row>
                                      <div className="col-md-6">
                                        <input 
                                          type="text" 
                                          name="phone" 
                                          id="phone" 
                                          placeholder="Votre numéro de téléphone" 
                                          defaultValue={phone}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="checkbox">
                                          <input 
                                            type="checkbox" 
                                            name="hidephone" 
                                            id="hidephone"
                                            onChange={this.handleHidePhone}
                                            checked={contact_phone}
                                          />
                                          <label htmlFor="hidephone">
                                            Cacher
                                          </label>
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Ville</h5>
                                  <div className="inputs">
                                    <select name="city" id="city">
                                      <option value="anglet">Anglet</option>
                                      <option value="anglet">Bayonne</option>
                                      <option value="anglet">Biarritz</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Mots clés</h5>
                                  <div className="inputs">
                                    <input 
                                      type="text" 
                                      name="tags" 
                                      id="tags" 
                                      defaultValue={tags}
                                    />
                                    <span>Entrez vos mots clés séparés par des virgules.</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </Row>
                        </form>
                      </DashboardViewport>
                    </div>
                    <div className="col-lg-3">
                      
                    </div>
                  </Row>
                </View>
              </React.Fragment>
            </React.Fragment>
          )
        }
      </MainContainer>
    )
  }
}

export default AdForm