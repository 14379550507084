import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';
import {
  AdTitleBar,
  AdContainer,
  SliderContainer,
  MainContainer
} from '../containers/Ads'
import Slider from './slider/Slick';
import Social from './Social';
import CardIcon from '../assets/icons/credit-card.svg';
import PhoneIcon from '../assets/icons/phone-solid.svg';
import MarkerIcon from '../assets/icons/map-marker-solid.svg';
import ClockIcon from '../assets/icons/clock.svg';
import ViewIcon from '../assets/icons/eye-solid.svg';
import MailIcon from '../assets/icons/envelope.svg';

export default class extends React.Component {

  state = {
    loaded: false,
    data: {
      product_name: null,
      category: null,
      catlink: null,
      sub_category: null,
      subcatlink: null,
      pictures: null,
      cityname: null,
      statename: null,
      price: null,
      negotiable: null,
      created_at: null,
      contact_phone: null,
      phone: null,
      view: null,
      description: null,
      user: { 
        name: null
      }
    },
    place: null,
    category: null,
    sub_category: null,
    id_place: null,
    id_category: null,
    id_sub_category: null,
    mail_ubject: null,
  }

  componentDidMount() {
    const { id, slug } = this.props;
    const _self = this;

    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_ads',
      id: parseInt(id),
      slug: slug,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (Object.keys(result.data.collection).length === 1) {
        _self.setState({ 
          data: result.data.collection[id],
          place: result.data.place,
          category: result.data.category,
          sub_category: result.data.sub_category,
          id_place: result.data.id_place,
          id_category: result.data.id_category,
          id_sub_category: result.data.id_sub_category,
          mail_subject: `AFC Bayonne Anglet Biarritz - Annonce : ${result.data.collection[id].product_name}`,
          loaded: true
        })
      }
    });
  }

  render() {
    const { 
      id_category, 
      id_sub_category,
      mail_subject,
      data: {
        product_name,
        category,
        catlink,
        sub_category,
        subcatlink,
        pictures,
        cityname,
        statename,
        price,
        negotiable,
        created_at,
        contact_phone,
        phone,
        view,
        description,
        user
      }
    } = this.state;
    console.log(this.state);
    return (
      <MainContainer className={this.state.loaded ? 'loaded' : ''}>
        {
          this.state.data && (
            <React.Fragment>
              <AdContainer>

                <AdTitleBar>
                  <div className="container">
                    <h2>{product_name}</h2>
                    <nav id="breadcrumb">
                      <ul>
                        <li><Link to="/annonces">Accueil</Link></li>
                        <li>
                          <Link to={`/annonces/categorie/${this.state.id_category}${catlink}`}>
                            {category}
                          </Link>
                        </li>
                        <li>
                          <Link to={`/annonces/categorie/${id_category}/${id_sub_category}${subcatlink}`}>
                            {sub_category}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </AdTitleBar>

                <SliderContainer>
                  <Slider {...pictures} />
                </SliderContainer>
              </AdContainer>

              <AdContainer style={{ paddingTop: '50px' }}>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8">
                      <div className="headline">
                        <h3>Détails de l'annonce</h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="property">
                              <MarkerIcon />
                              <div>
                                <span>Lieu</span>
                                <h5>{cityname}, {statename}</h5>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="property">
                              <CardIcon />
                              <div>
                                <span>Prix</span>
                                <h5>
                                  {price} €
                                  {
                                    negotiable && (
                                      <>
                                        {` `}<span style={{
                                          background: '#e0f5d7',
                                          color: '#449626',
                                          display: 'inline'
                                        }}>négociable</span>
                                      </>
                                    )
                                  }
                                </h5>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="property">
                              <ClockIcon />
                              <div>
                                <span>Posté il y a</span>
                                <h5>{created_at}</h5>
                              </div>
                            </div>
                          </div>
                          
                          {
                            contact_phone && (
                              <div className="col-md-6">
                                <div className="property">
                                  <PhoneIcon />
                                  <div>
                                    <span>Numéro de téléphone</span>
                                    <h5>{phone}</h5>
                                  </div>
                                </div>
                              </div>
                            )
                          }

                          <div className="col-md-6">
                            <div className="property">
                              <ViewIcon />
                              <div>
                                <span>Vues</span>
                                <h5>{view}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="content">
                        <h3>Description</h3>
                        <div dangerouslySetInnerHTML={{ __html: description }}></div>
                      </div>

                      <div className="social-widget">
                        <Social title={product_name} link={this.props.location.href} />
                      </div>
                    </div>

                    <div className="col-xl-4">
                      <div className="sidebar-container">
                        <div className="sidebar-widget">
                          <div className="detail">
                            <div className="header">
                              Contacter l'utilisateur
                            </div>
                            <div className="body">
                              <div className="avatar">
                                <Link to={`/annonces/profil/${user.id}/${user.username}`}>
                                  <img src={`${process.env.AFC_STORAGE}/profile/${(typeof user.image !== 'undefined' ? user.image : 'default_user.png')}`} alt={user.username} />
                                </Link>
                              </div>
                              <h2>
                                <Link to={`/annonces/profil/${user.id}/${user.username}`}>
                                  {user.name}
                                </Link>
                              </h2>
                              <ul>
                                <li><ClockIcon /> <span>{created_at}</span></li>
                                <li><PhoneIcon /> <span>Afficher le n° de téléphone</span></li>
                              </ul>
                              <a href={`mailto:${user.email}?subject=${mail_subject}`} className="button">
                                Répondre par mail
                                <MailIcon />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </AdContainer>
            </React.Fragment>
          )
        }
      </MainContainer>
    )
  }
}