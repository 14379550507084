import React from "react"
import axios from 'axios';
import Uploader from "./Upload/AdsUpload"
// import Editor from "./Editor/Wysiwyg"
import { MainContainer, View, Row } from '../containers/MainWrapper'
import Head from "./Dashboard/Head"
import { DashboardViewport } from "../containers/Dashboard"

const cities = {
  anglet: 'Anglet',
  bayonne: 'Bayonne',
  biarritz: 'Biarritz'
};

class AdForm extends React.Component {

  state = {
    loaded: true,
    data: {
      product_name: null,
      category: null,
      catlink: null,
      sub_category: null,
      subcatlink: null,
      pictures: null,
      pictures_data: null,
      cityname: null,
      statename: null,
      price: null,
      negotiable: null,
      created_at: null,
      contact_phone: null,
      phone: null,
      description: null,
      tags: null,
      user: { 
        name: null
      }
    },
    place: null,
    category: null,
    sub_category: null,
    id_place: null,
    id_category: null,
    id_sub_category: null,
    categoriesTree: null,
  }
  
  componentDidMount() {
    const _self = this;
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_ads_categories'
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (result) {
        const { categories, sub_categories } = result.data;
        const categoriesTree = categories.map((item) => {
          const subcategories = sub_categories.filter((i) => parseInt(i.main_cat_id) === parseInt(item.cat_id));
          return {
            ...item,
            subcategories: subcategories
          }
        }).filter(item => item.subcategories.length > 0);

        _self.setState({ 
          categoriesTree: categoriesTree,
          loaded: true
        })
      }
    });
  }

  handleCategory = () => {

  }

  handleTitle = () => {

  }

  handleDescription = () => {

  }

  handleImages = () => {

  }

  handlePrice = () => {

  }

  handleNegotiable = () => {

  }

  handleHidePhone = () => {

  }

  handlePhone = () => {

  }

  handleHidePhone = () => {

  }

  handleCity = () => {

  }

  handleTags = () => {

  }

  render() {

    const { categoriesTree } = this.state;

    return (
      <MainContainer 
        style={{ background: '#f8f8f8' }} 
        className={this.state.loaded ? 'loaded' : ''}
      >
        {
          this.state.loaded && (
            <React.Fragment>
              <React.Fragment>
                <Head page={this.props.page} />
                <View>
                  <Row>
                    <div className="col-lg-9">
                      <DashboardViewport>
                        <form method="post">
                          <div className="headline">Détails de l'annonce</div>
                          <Row>
                            <div className="col-md-12">
                              <div className="content">

                                <div className="fields">
                                  <h5>Catégorie</h5>
                                  <div className="inputs">
                                    <select name="category" id="category">
                                      {
                                        categoriesTree && categoriesTree.map((item, key) => {
                                          return (
                                            <optgroup label={item.cat_name} key={`main-category-${key}`}>
                                              {
                                                item.subcategories.map((i, index) => {
                                                  return (
                                                    <option value={i.sub_cat_id} key={`sub-category-${index}`}>
                                                      {i.sub_cat_name}
                                                    </option>
                                                  )
                                                })
                                              }
                                            </optgroup>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Titre</h5>
                                  <div className="inputs">
                                    <input 
                                      type="text" 
                                      name="name" 
                                      id="name" 
                                      onChange={this.handleTitle}
                                    />
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Description</h5>
                                  <div className="inputs">
                                    {/* <Editor defaultValue={``} /> */}
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Images</h5>
                                  <div className="inputs">
                                    <Uploader 
                                      images={[]}
                                      pictures_data={[]}
                                    />
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Prix</h5>
                                  <div className="inputs">
                                    <Row>
                                      <div className="col-md-6">
                                        <input 
                                          type="text" 
                                          name="price" 
                                          id="price" 
                                          onChange={this.handlePrice}
                                          placeholder="Saisissez votre prix"
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="checkbox">
                                          <input 
                                            type="checkbox" 
                                            name="negociate" 
                                            id="negociate"
                                            onChange={this.handleNegotiable}
                                          />
                                          <label htmlFor="negociate">
                                            Négociable
                                          </label>
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Numéro de téléphone</h5>
                                  <div className="inputs">
                                    <Row>
                                      <div className="col-md-6">
                                        <input 
                                          type="text" 
                                          name="phone" 
                                          id="phone" 
                                          placeholder="Votre numéro de téléphone" 
                                          onChange={this.handlePhone}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <div className="checkbox">
                                          <input 
                                            type="checkbox" 
                                            name="hidephone" 
                                            id="hidephone"
                                            onChange={this.handleHidePhone}
                                          />
                                          <label htmlFor="hidephone">
                                            Cacher
                                          </label>
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Ville</h5>
                                  <div className="inputs">
                                    <select name="city" id="city">
                                      {
                                        Object.keys(cities).map((item, index) => {
                                          return (
                                            <option value={item} key={`city-option-${index}`}>
                                              {cities[item]}
                                            </option>
                                          )
                                        })
                                      }
                                    </select>
                                  </div>
                                </div>

                                <div className="fields">
                                  <h5>Mots clés</h5>
                                  <div className="inputs">
                                    <input 
                                      type="text" 
                                      name="tags" 
                                      id="tags" 
                                      onChange={this.handleTags}
                                    />
                                    <span>Entrez vos mots clés séparés par des virgules.</span>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </Row>
                        </form>
                      </DashboardViewport>
                    </div>
                    <div className="col-lg-3">
                      
                    </div>
                  </Row>
                </View>
              </React.Fragment>
            </React.Fragment>
          )
        }
      </MainContainer>
    )
  }
}

export default AdForm