import React from 'react'
// import { Link } from 'gatsby'
import styled from '@emotion/styled'
// import FavIcon from '../assets/icons/heart-solid.svg';
import MailIcon from '../assets/icons/envelope.svg';
import FacebookIcon from '../assets/icons/facebook-f.svg';
import TwitterIcon from '../assets/icons/twitter.svg';
import LinkedinIcon from '../assets/icons/linkedin-in.svg';
import WhatsAppIcon from '../assets/icons/whatsapp.svg';

// const FavButton = styled.button`
//   background-color: #404040;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   position: relative;
//   height: 44px;
//   line-height: 44px;
//   color: #fff;
//   padding: 0 15px;
//   padding-left: 59px;
//   border-radius: 4px;
//   transition: .4s;
//   cursor: pointer;
//   box-shadow: 0 3px 12px rgb(0 0 0 / 10%);
//   margin-bottom: 20px;
//   font-size: 16px;
//   .fav-icon {
//     width: 44px;
//     height: 44px;
//     background-color: rgba(255,255,255,.05);
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: -2px;
//     left: -2px;
//     z-index: 101;
//     svg {
//       fill: white;
//       height: 18px;
//     }
//   }
// `;

const ShareButtons = styled.ul`
  padding: 0;
  margin: 0 0 30px 0;
  li {
    display: inline-block;
    list-style-type: none;
    margin: 0 5px;
    &:first-of-type {
      margin-left: 0;
    }
    a {
      height: 42px;
      width: 42px;
      border-radius: 2px;
      display: flex;
      line-height: 40px;
      align-items: center;
      justify-content: center;
      background-color: #333;
      color: #fff;
      font-size: 16px;
      svg {
        fill: white;
        height: 20px;
      }
    }
  }
`;

export default class extends React.Component {

  render() {
    const { title, link } = this.props;

    return (
      <React.Fragment>
        {/* <FavButton>
          <span className="fav-icon"><FavIcon /></span>
          <span className="fav-text">Enregistrer cette annonce</span>
        </FavButton> */}
        <ShareButtons>
          <li>
            <a 
              rel="noreferrer" 
              target="_blank" 
              style={{ background: '#dd4b39' }} 
              href={`mailto:?subject=${title}&body=${link}`}
            >
              <MailIcon />
            </a>
          </li>
          <li>
            <a 
              rel="noreferrer" 
              target="_blank" 
              style={{ background: '#3B5998' }} 
              href={`https://facebook.com/sharer/sharer.php?u=${link}`}
            >
              <FacebookIcon />
            </a>
          </li>
          <li>
            <a 
              rel="noreferrer" 
              target="_blank" 
              style={{ background: '#1DA1F2' }} 
              href={`https://twitter.com/share?url=${link}&text=${title}`}
            >
              <TwitterIcon />
            </a>
          </li>
          <li>
            <a 
              rel="noreferrer" 
              target="_blank" 
              style={{ background: '#0077B5' }} 
              href={`https://www.linkedin.com/shareArticle?mini=true&url=${link}`}
            >
              <LinkedinIcon />
            </a>
          </li>
          <li>
            <a 
              rel="noreferrer" 
              target="_blank" 
              style={{ background: '#25D366' }} 
              href={`https://web.whatsapp.com/send?text=${link}`}
            >
              <WhatsAppIcon />
            </a>
          </li>
        </ShareButtons>
      </React.Fragment>
    )
  }
}