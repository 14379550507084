import React from 'react';
import { Link } from 'gatsby'
import Slider from "react-slick";
import { CustomPrevArrow, CustomNextArrow } from './CustomArrows'
import { Slide } from '../../containers/Ads'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default class extends React.Component {

  componentDidMount() {
    window.addEventListener('keydown', this.keyPressHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyPressHandler);
  }

  keyPressHandler = (e) => {
    if (e.keyCode === 39) {
      this.next();
    } else if (e.keyCode === 37) {
      this.previous();
    }
  }

  next = () => {
    this.slider.slickNext();
  }

  previous = () => {
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      prevArrow: <CustomPrevArrow />,
      nextArrow: <CustomNextArrow />,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: '30%',
      lazyLoad: 'ondemand',
      centerMode: true,
      responsive: [{
        breakpoint: 1367,
        settings: {
          centerPadding: '21%'
        }
      }, {
        breakpoint: 993,
        settings: {
          centerPadding: '0'
        }
      }]
    };
    
    const images = this.props;
    return (
      <Slider ref={c => (this.slider = c)} {...settings}>
        {
          Object.keys(images).length > 0 && Object.keys(images).map((key) => {
            const item = images[key];
            return (
              <Slide key={`product-${key}`}>
                <Link to="/" style={{
                  background: `url(${process.env.AFC_STORAGE}/products/${item})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}>
                  <img 
                    src={`${process.env.AFC_STORAGE}/products/${item}`} 
                    alt={`product-file-${key}`}
                  />
                </Link>
              </Slide>
            )
          })
        }
      </Slider>
    )
  }
}
