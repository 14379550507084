import React from 'react';
import { Link } from 'gatsby'
import axios from 'axios';
import { 
  AdTitleBar, 
  AdContainer, 
  AdGridLayout, 
  AdGridItem,
  MainContainer
} from '../containers/Ads'
import CardIcon from '../assets/icons/credit-card.svg';
import UserIcon from '../assets/icons/user.svg';
import MarkerIcon from '../assets/icons/map-marker-solid.svg';
import ClockIcon from '../assets/icons/clock.svg';
import TagsIcon from '../assets/icons/tags-solid.svg';

export default class extends React.Component {

  constructor() {
    super()
    this.state = {
      loaded: false,
      data: null,
      place: null,
      category: null,
      sub_category: null,
      id_place: null,
      id_category: null,
      id_sub_category: null,
    }
  }
  
  componentDidMount() {
    const _self = this;
    const { idcategory, idsubcategory, idcity } = this.props;
    const payload = {
      category: idcategory && parseInt(idcategory),
      sub_category: idsubcategory && parseInt(idsubcategory),
      city: idcity && parseInt(idcity),
    }
    console.log(payload);
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_ads',
      ...payload
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (result.data.collection.length > 0) {
        _self.setState({ 
          data: result.data.collection,
          place: result.data.place,
          category: result.data.category,
          sub_category: result.data.sub_category,
          id_place: result.data.id_place,
          id_category: result.data.id_category,
          id_sub_category: result.data.id_sub_category,
          loaded: true
        })
      }
    });
  }

  render() {
    
    const { 
      category, 
      idcategory, 
      subcategory, 
      idsubcategory, 
      // city, 
      // idcity 
    } = this.props;
    console.log(this.state);
    return (
      <MainContainer className={this.state.loaded ? 'loaded' : ''}>
        <AdContainer>
          <AdTitleBar>
            <div className="container">
              <h2>Toutes les annonces</h2>
              <nav id="breadcrumb">
                <ul>
                  <li><Link to="/annonces">Accueil</Link></li>
                  {
                    category ? (
                      <li>
                        <Link to={`/annonces/categorie/${idcategory}/${category}`}>
                          {this.state.category}
                        </Link>
                      </li>
                    ) : (
                      <li><Link to="/annonces/liste">Toutes catégories</Link></li>
                    )
                  }
                  {
                    subcategory && (
                      <li>
                        <Link to={`/annonces/categorie/${idcategory}/${idsubcategory}/${category}/${subcategory}`}>
                          {this.state.sub_category}
                        </Link>
                      </li>
                    )
                  }
                </ul>
              </nav>
            </div>
          </AdTitleBar>
        </AdContainer>

        <AdContainer>
          <div className="container">
            <AdGridLayout>
              {
                this.state.data && this.state.data.map((item, index) => {
                  return (
                    <AdGridItem key={`ad-item-${index}`}>
                      <div className="details">
                        <div className="hero">
                          <Link to={`/annonces/${item.id}/${item.link}`}>
                            <img 
                              src={`${process.env.AFC_STORAGE}/products/thumb/${item.picture}`} 
                              alt={item.product_name} 
                            />
                          </Link>
                        </div>

                        <div className="description">
                          <h3>
                            <Link to={`/annonces/${item.id}/${item.link}`}>
                              {item.product_name}
                            </Link>
                          </h3>
                          <ol className="breadcrumb">
                            <li>
                              <Link to={`/annonces/categorie/${item.category_id}${item.catlink}`}>
                                <TagsIcon /> {item.category}
                              </Link>
                            </li>
                            <li>
                              <Link to={`/annonces/categorie/${item.category_id}/${item.sub_category_id}${item.subcatlink}`}>
                                {item.sub_category}
                              </Link>
                            </li>
                          </ol>
                          <h5>
                            <Link to={`/annonces/profil/${item.author_id}/${item.username}`}><UserIcon /> {item.username}</Link>
                          </h5>
                        </div>
                      </div>

                      <div className="footer">
                        <ul>
                          <li>
                            <Link to={`/annonces/lieu/${item.city}${item.citylink}`}>
                              <MarkerIcon /> {item.cityname}
                            </Link>
                          </li>
                          <li>
                            <span>
                              <CardIcon /> {item.price} €
                            </span>
                          </li>
                          <li><span><ClockIcon /> {item.created_at}</span></li>
                        </ul>
                      </div>
                    </AdGridItem>
                  )
                })
              }
            </AdGridLayout>
          </div>
        </AdContainer>
      </MainContainer>
    )
  }
}