import React from 'react';
import { Link } from 'gatsby';
import axios from 'axios';

import { MainContainer } from '../containers/MainWrapper'
import { PublicProfileContainer, ProfileCard } from '../containers/PublicProfile'
import {
  AdContainer,
  AdGridLayout,
  AdGridItem
} from '../containers/Ads'

import TextTruncate from 'react-text-truncate';
import CardIcon from '../assets/icons/credit-card.svg';
import UserIcon from '../assets/icons/user.svg';
import MarkerIcon from '../assets/icons/map-marker-solid.svg';
import ClockIcon from '../assets/icons/clock.svg';
import TagsIcon from '../assets/icons/tags-solid.svg';

export default class extends React.Component {

  constructor() {
    super();
    this.state = {
      loaded: false,
      data: {
        user: {
          avatar: null,
          created_at: null,
          email: null,
          id: null,
          last_activity: null,
          username: null,
          name: null
        },
        ads: {
          collection: []
        }
      }
    }
  }

  componentDidMount() {
    const { id, slug } = this.props;
    const _self = this;

    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_user_profile',
      id: parseInt(id),
      slug: slug,
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (Object.keys(result.data.user).length > 0) {
        _self.setState({ 
          data: result.data,
          loaded: true
        });
      }
    });
  }

  render() {
    const {
      user: {
        avatar,
        created_at,
        username,
        name
      },
      ads: {
        collection
      }
    } = this.state.data;
    
    return (
      <MainContainer className={this.state.loaded ? 'loaded' : ''}>
        {
          this.state.loaded && (
            <PublicProfileContainer>
              <div className="header">
                <AdContainer>
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8">
                        <ProfileCard>
                          <div className="avatar">
                            <img 
                              src={`${process.env.AFC_STORAGE}/profile/small_${avatar}`} 
                              alt={username}
                            />
                          </div>
                          <div className="content">
                            <div className="name">
                              <h4>{name}</h4>
                              <span>@{username}</span>
                              <hr />
                              <small>Membre depuis {created_at}</small>
                              <small>{collection.length} annonce{collection.length > 1 ? 's' : ''}</small>
                            </div>
                          </div>
                        </ProfileCard>
                      </div>
                    </div>
                  </div>
                </AdContainer>
              </div>

              <AdContainer>
                <div className="container">
                  <AdGridLayout>
                    {
                      collection.length > 0 && collection.map((item, index) => {
                        return (
                          <AdGridItem key={`ad-item-${index}`} className="large">
                            <div className="details">
                              <div className="hero">
                                <Link to={`/annonces/${item.id}/${item.link}`}>
                                  <img 
                                    src={`${process.env.AFC_STORAGE}/products/thumb/${item.picture}`} 
                                    alt={item.product_name} 
                                  />
                                </Link>
                              </div>

                              <div className="description">
                                <h3>
                                  <Link to={`/annonces/${item.id}/${item.link}`}>
                                    {item.product_name}
                                  </Link>
                                </h3>
                                <TextTruncate
                                  line={2}
                                  element="p"
                                  truncateText="…"
                                  text={item.description}
                                />
                                <ol className="breadcrumb">
                                  <li>
                                    <Link to={`/annonces/categorie/${item.category_id}${item.catlink}`}>
                                      <TagsIcon /> {item.category}
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to={`/annonces/categorie/${item.category_id}/${item.sub_category_id}${item.subcatlink}`}>
                                      {item.sub_category}
                                    </Link>
                                  </li>
                                </ol>
                                <h5>
                                  <Link to={`/`}><UserIcon /> {item.username}</Link>
                                </h5>
                              </div>
                            </div>

                            <div className="footer">
                              <ul>
                                <li>
                                  <Link to={`/annonces/lieu/${item.city}${item.citylink}`}>
                                    <MarkerIcon /> {item.cityname}
                                  </Link>
                                </li>
                                <li>
                                  <span>
                                    <CardIcon /> {item.price} €
                                  </span>
                                </li>
                                <li><span><ClockIcon /> {item.created_at}</span></li>
                              </ul>
                            </div>
                          </AdGridItem>
                        )
                      })
                    }
                  </AdGridLayout>
                </div>
              </AdContainer>
            </PublicProfileContainer>
          )
        }
      </MainContainer>
    )
  }
}