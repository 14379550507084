import styled from '@emotion/styled'

export const PublicProfileContainer = styled.div`
  .header {
    background: #f8f8f8;
    position: relative;
    padding: 40px 0;
    margin-bottom: 40px;
  }
`;

export const ProfileCard = styled.div`
  margin-bottom: 50px;
  padding: 0;
  display: flex;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
  background: #fff;
  border-radius: 3px;

  .avatar {
    width: 200px;
    height: 200px;
    overflow: hidden;
    img {
      height: auto;
      width: 100%;
    }
  }
  .content {
    padding: 0 40px;
    padding-top: 30px;
    padding-bottom: 19px;
    margin: 0;
    border: none;
    .name {
      h4 {
        font-size: 24px;
        color: #66676b;
        margin: 0;
        font-weight: 500;
      }
      span, small, a {
        font-size: 20px;
        color: #888;
        margin-bottom: 3px;
        margin-top: 3px;
        display: block;
      }
      hr {
        margin: 10px 0;
        border: none;
        border-bottom: 1px solid #eee;
      }
      small {
        font-size: 16px;
      }
    }
    .about {
      word-break: break-word;
      font-size: 16px;
    }
    .details {
      border-radius: 3px;
      background: linear-gradient(to left,#fff 0,#f7f7f7);
      list-style: none;
      margin: 0;
      color: #909090;
      display: block;
      padding: 18px 32px;
      li {
        position: relative;
        margin-left: 28px;
        line-height: 24px;
        padding: 3px 0;
        word-break: break-all;
        a {
          color: #909090;
        }
      }
    }
  }
`;