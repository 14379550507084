import React from 'react'
import { Router } from "@reach/router"
import PrivateRoute from "../components/PrivateRoute"
import Ad from "../components/Ad"
import LatestAds from "../components/LatestAds"
import SearchAds from "../components/SearchAds"
import PublicProfile from "../components/PublicProfile"
import AdForm from "../components/AdForm"
import AdEditForm from "../components/AdEditForm"
import MainWrapper from '../containers/MainWrapper'
import Header from '../components/Header'
import SEO from '../components/SEO'

const Annonces = ({
  pageContext: { slug },
  location
}) => {
  return (
    <React.Fragment>
      <SEO />
      <Header slug={slug} location={location} />
      <MainWrapper>
        {
          process.env.NODE_ENV === 'development' && (
            <Router basepath="/annonces">
              <LatestAds path="/" />
              
              <SearchAds path="/liste" />
              <SearchAds path="/categorie/:idcategory/:category" />
              <SearchAds path="/categorie/:idcategory/:idsubcategory/:category/:subcategory" />
              <SearchAds path="/lieu/:idcity/:city" />
              
              <Ad path="/:id/:slug" />
              
              <PublicProfile path="/profil/:id/:slug" />
              
              <AdForm 
                path="/collection/creer" 
                page="new"
              />
              <PrivateRoute 
                path="/collection/:iduser/:id/modifier" 
                component={AdEditForm} 
                page="editad"
              />
            </Router>
          )
        }
      </MainWrapper>
    </React.Fragment>
  )
}
export default Annonces