import React from 'react';
import ImageUploading from 'react-images-uploading';
import { Row } from '../../containers/MainWrapper'
import { ImgThumb, UploadMainButtons } from '../../containers/Ads'
// import axios from 'axios';

const Uploader = (props) => {
  const [images, setImages] = React.useState([...props.pictures_data]);
  const maxNumber = 10;
  
  const onChange = (imageList, addUpdateIndex) => {
    // axios.post(`${process.env.AFC_API_ENDPOINT}`, {
    //   action: 'upload',
    //   user: props.user,
    //   file_name: imageList[0].file.name,
    //   data_url: imageList[0].data_url,
    // }, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   }
    // }).then(res => {
    //   console.log(res)
    //   document.getElementById('profile-avatar').setAttribute("src", imageList[0].data_url);
    // });
    setImages(imageList);
  };
  
  return (
    <ImageUploading
      multiple
      value={images}
      onChange={onChange}
      maxNumber={maxNumber}
      dataURLKey="data_url"
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        <div className="upload__image-wrapper">
          <UploadMainButtons
            style={isDragging ? { color: 'red' } : undefined}
            onClick={(e) => { e.preventDefault(); onImageUpload(); }}
            {...dragProps}
          >
            Télécharger une image
          </UploadMainButtons>
          {
            imageList.length > 0 && (
              <UploadMainButtons onClick={(e) => { e.preventDefault(); onImageRemoveAll(); }}>
                Supprimer toutes les images
              </UploadMainButtons>
            )
          }
          <Row>
            {imageList.map((image, index) => (
              <ImgThumb key={index} className="col-lg-3">
                <div className="img-container">
                  <img src={image['data_url']} alt="" width="100" />
                </div>
                <div className="buttons">
                  <button onClick={(e) => { e.preventDefault(); onImageUpdate(index); }}>Modifier</button>
                  <button onClick={(e) => { e.preventDefault(); onImageRemove(index); }}>Supprimer</button>
                </div>
              </ImgThumb>
            ))}
          </Row>
        </div>
      )}
    </ImageUploading>
  );
}

export default Uploader;