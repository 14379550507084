import React from 'react';
import styled from '@emotion/styled'
import Prev from '../../assets/prev.svg';
import Next from '../../assets/next.svg';


const PrevButton = styled.button`
  left: 2rem;
  height: 40px!important;
  z-index:3;
  &:before {
    display: none!important;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`
const NextButton = styled.button`
  right: 2rem;
  height: 40px!important;
  z-index:3;
  &:before {
    display: none!important;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`

export const CustomPrevArrow = ({ className, onClick }) => (
  <PrevButton type="button" className={className} onClick={onClick}>
    <Prev />
  </PrevButton>
)

export const CustomNextArrow = ({ className, onClick }) => (
  <NextButton type="button" className={className} onClick={onClick}>
    <Next />
  </NextButton>
)