import React from 'react';
import { Link } from 'gatsby'
import axios from 'axios';
// import HomeMap from './Map';
import {
  AdsHomeContainer,
  LatestAds,
  MainContainer
} from '../containers/Ads'

import TextTruncate from 'react-text-truncate';
import CardIcon from '../assets/icons/credit-card.svg';
import UserIcon from '../assets/icons/user.svg';
import MarkerIcon from '../assets/icons/map-marker-solid.svg';
import ClockIcon from '../assets/icons/clock.svg';
import TagsIcon from '../assets/icons/tags-solid.svg';

export default class extends React.Component {

  constructor() {
    super()
    this.state = {
      loaded: false,
      data: null
    }
  }
  
  componentDidMount() {
    const _self = this;
    axios.post(`${process.env.AFC_API_ENDPOINT}`, {
      action: 'get_ads',
      limit: 5
    }, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then((result) => {
      if (result.data.collection.length > 0) {
        _self.setState({ 
          data: result.data.collection,
          loaded: true
        })
      }
    });
  }

  render() {
    console.log(this.state)
    return (
      <MainContainer className={this.state.loaded ? 'loaded' : ''}>
        {/* <HomeMap /> */}
        <section>
          <div className="container">
            <AdsHomeContainer>
              <div className="headline">
                <h3>Dernières annonces</h3>
                <Link to="/annonces/liste">Afficher plus</Link>
              </div>
              <LatestAds>
                {
                  this.state.data && this.state.data.map((item, index) => {
                    return (
                      <div key={`ad-${index}`}>
                        <div className="details">
                          <div className="image-container">
                            <img 
                              src={`${process.env.AFC_STORAGE}/products/thumb/${item.picture}`} 
                              alt={item.product_name} 
                            />
                          </div>
                          <div className="description">
                            <div className="description-top">
                              <h3>
                                <Link to={`/annonces/${item.id}/${item.link}`}>
                                  {item.product_name}
                                </Link>
                              </h3>
                              <div className="category">
                                <Link to={`/annonces/categorie/${item.category_id}${item.catlink}`}>
                                  <TagsIcon /> {item.category}
                                </Link>
                              </div>
                            </div>
                            <div className="footer">
                              <ul>
                                <TextTruncate
                                  line={2}
                                  element="li"
                                  truncateText="…"
                                  text={item.description}
                                />
                              </ul>
                              <ul>
                                <li>
                                  <Link to={`/annonces/profil/${item.author_id}/${item.username}`}>
                                    <UserIcon /> {item.username}
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/annonces/lieu/${item.city}${item.citylink}`}>
                                    <MarkerIcon /> {item.cityname}
                                  </Link>
                                </li>
                                <li><CardIcon /> {item.price} €</li>
                                <li><ClockIcon /> {item.created_at}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </LatestAds>
            </AdsHomeContainer>
          </div>
        </section>
      </MainContainer>
    )
  }
}